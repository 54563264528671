<template>
  <Page>
    <template v-slot:content>
      <DropFilters @onFiltersChanged="onFiltersChanged" />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page="table.options.page"
          :server-items-length="result.count"
          @update:page="load"
          @update:sort-by="load"
          @update:sort-desc="load"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.status="{ item }">
            <StatusChip :status="getStatus(item)" />
          </template>
          <template v-slot:item.name="{ item }">
            <a :href="`/products/list?drop.id=${item.id}`">
              {{ item.name }}
            </a>
            <template v-if="getStatus(item) === 'soon' && getMarketplacePreviewDropUrl(item)">
              &nbsp;
              (<a
                :href="getMarketplacePreviewDropUrl(item)"
                target="_blank"
              >preview</a>
              <v-icon
                title="copier"
                class="icon-copy"
                color="blue lighten-1"
                small
                @click="copyToClipBoard(getMarketplacePreviewDropUrl(item))"
              >
                mdi-content-copy
              </v-icon>)
            </template>
          </template>
          <template v-slot:item.slug="{ item }">
            {{ item.slug }}
            <a
              :href="getMarketplaceDropUrl(item)"
              target="_blank"
            >
              <v-icon
                small
                color="blue lighten-1"
              >mdi-open-in-new</v-icon>
            </a>
          </template>
          <template v-slot:item.public="{ item }">
            <span
              :class="item.public ? 'green--text' : 'red--text'"
            >
              {{ item.public ? '✓' : '✕' }}
            </span>
          </template>
          <template v-slot:item.published="{ item }">
            <span
              :class="item.published ? 'green--text' : 'red--text'"
            >
              {{ item.published ? '✓' : '✕' }}
            </span>
          </template>
          <template v-slot:item.exclusive="{ item }">
            <span
              :class="item.exclusive ? 'green--text' : 'red--text'"
            >
              {{ item.exclusive ? '✓' : '✕' }}
            </span>
          </template>
          <template v-slot:item.stock_for_sale="{ item }">
            <span :class="item.stock_for_sale.available > 0 ? 'green--text' : 'red--text'">
              {{ item.stock_for_sale.available }}
            </span>
            ({{ item.stock_for_sale.total }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>Stock total (tous les produits)</span>
            </v-tooltip>)
          </template>
          <template v-slot:item.pre_launch_at="{ item }">
            <span :title="item.pre_launch_at | date">
              {{ item.pre_launch_at | date }}
            </span>
          </template>
          <template v-slot:item.launch_at="{ item }">
            <span :title="item.launch_at | date">
              {{ item.launch_at | date }}
            </span>
          </template>
          <template v-slot:item.end_at="{ item }">
            <span :title="item.end_at | date">
              {{ item.end_at | date }}
            </span>
          </template>
        </v-data-table>
      </v-sheet>
    </template>
  </Page>
</template>

<script>
import { getQueryAsBoolean, getQueryString } from '@/helpers/queryString'
import DropFilters from '@/views/Drops/Filters.vue'
import dropMixin from '@/mixins/drop'
import Page from '@/components/core/Page.vue'
import snackbarMixin from '@/mixins/snackbar'
import StatusChip from '@/components/base/Drop/StatusChip.vue'

export default {
  name: 'DropsList',
  components: { Page, StatusChip, DropFilters },
  mixins: [dropMixin, snackbarMixin],
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
        public: getQueryAsBoolean(this.$route.query, 'public'),
        published: getQueryAsBoolean(this.$route.query, 'published'),
        exclusive: getQueryAsBoolean(this.$route.query, 'exclusive'),
        status: this.$route.query['status'],
      },
      table: {
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortBy: ['launchAt'],
          sortDesc: [true],
          mustSort: true,
        },
        headers: [
          {
            text: this.$i18n.t('views.drops.headers.name'),
            value: 'name',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.slug'),
            value: 'slug',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.public'),
            value: 'public',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.published'),
            value: 'published',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.exclusive'),
            value: 'exclusive',
            sortable: false,
          },
          {
            text: 'Stock en vente',
            value: 'stock_for_sale',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.status'),
            value: 'status',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.pre_launch_date'),
            value: 'pre_launch_at',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.drops.headers.launch_date'),
            value: 'launch_at',
            sortable: true,
          },
          {
            text: this.$i18n.t('views.drops.headers.end_date'),
            value: 'end_at',
            sortable: false,
          },
        ],
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
    }
  },
  computed: {
    queryString: function () {
      return '/catalog/drops?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    filters() {
      this.table.options.page = 0
    },
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'DropsList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    copyToClipBoard (text) {
      navigator.clipboard.writeText(text)
    },
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'DropsList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    async load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      try {
        const response = await this.$axios.get(this.queryString, {
          cancelToken: axiosSource.token,
        })
        this.result.items = response.data['hydra:member']
        this.result.count = response.data['hydra:totalItems']
        this.result.loading = false
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      } finally {
        this.result.loading = false
        this.request = null
      }
    },
    getMarketplaceDropUrl(drop) {
      return new URL('/shop/drops/' + drop.slug + '/', process.env.VUE_APP_MP_BASE_URL)
    },

    getMarketplacePreviewDropUrl(drop) {
      if (!drop.preview_token) {
        return
      }

      return this.getMarketplaceDropUrl(drop) + drop.preview_token + '/'
    },
  },
}
</script>

<style scoped>
.icon-copy {
  margin-left: 5px;

  &:active {
    color: grey !important;
  }
}
</style>
