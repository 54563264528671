<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-toolbar
      color="transparent"
      flat
    >
      <v-btn
        v-if="!drawer"
        elevation="1"
        class="mr-2"
        fab
        small
        @click="setDrawer(!drawer)"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
      >
        <slot name="title">
          {{ $t($route.meta.title) }}
          <slot name="help" />
        </slot>
      </v-toolbar-title>
      <v-spacer />
      <slot name="toolbar-buttons" />
    </v-toolbar>
    
    <v-skeleton-loader
      v-if="!mounted"
      elevation="2"
      :loading="!mounted"
      type="card-heading, article@2"
    />
    <v-container
      v-else
      fluid
    >
      <slot name="content" />
    </v-container>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Page',
  props: {
    mounted: { type: Boolean, required: false, default: true },
  },
  computed: {
    ...mapState(['drawer']),
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setDrawer: 'drawerDisplay',
    }),
  },
}
</script>

<style scoped>

</style>
